import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import BenefitsList from "../components/BenefitsList";
import RenderMarkdown from "../components/utilities/RenderMarkdown";
import Video from "../components/Video";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage";
import Introduction from "../components/Introduction";
import FAQs from "../components/FAQs";
import Famous from "../components/Famous";
import Cta from "../components/CTA";
import CountdownTimer from "../components/CountdownTimer";
import FunnelForm from "../components/FunnelForm";
import Signup from "../components/Signup";
import Reviews from "../components/Reviews";
import TextReviews from "../components/TextReviews";

const OptInTimPageTemplate = ({ data }) => {
  return (
    <>
      <Layout
        seoObject={data.seoObject}
        imageObject={data.headerObject.imageObject}
      >
        {/*------------------------- hero -------------------------*/}
        <Section
          classNameInnerContainer="space-y-4 sm:space-y-10"
          className="bg-brand-beige-40"
        >
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            whitespace="normal"
            className="m-0 text-center space-y-1 sm:space-y-2"
          />

          <Video videoObject={data.heroObject.video} loading="eager" />
          {data.heroObject.subtitle && (
            <RenderMarkdown
              markdownContent={data.heroObject.subtitle}
              whitespace="normal"
              className="m-0 text-center"
            />
          )}
          <CountdownTimer
            date={data.configObject.date}
            className="my-10"
            classNameDigits="font-semibold"
          />
          <div className="mx-auto w-full max-w-2xl">
            <FunnelForm data={data.heroObject.signupObject} />
          </div>
        </Section>

        {/*------------------------- famous -------------------------*/}
        <Section className="bg-brand-mint">
          <Famous />
        </Section>

        {/*------------------------- youKnow -------------------------*/}
        <Section>
          <BenefitsList
            benefitsObject={data.youKnowObject}
            classNameIcon="h-10 text-brand-olive rotate-90"
            isIconCentered={true}
            whitespaceList="normal"
          />
        </Section>

        {/*------------------------- imagine -------------------------*/}
        <Section className="bg-brand-beige-40">
          <RenderMarkdown
            markdownContent={data.imagineObject.title}
            className="text-center"
          />
          <RenderMarkdown markdownContent={data.imagineObject.description} />
        </Section>

        {/*------------------------- pitch -------------------------*/}
        <Section className="bg-brand-mint">
          <div className="flex justify-center">
            <PreviewCompatibleImage
              imageObject={data.pitchBenefitsObject.imageObject}
              className="max-w-4xl w-full mb-5 sm:mb-10"
              loading="lazy"
            />
          </div>
          <BenefitsList
            benefitsObject={data.pitchBenefitsObject.benefitsObject}
            classNameIcon="w-11 p-2 text-brand-black"
          />
          <Cta
            ctaObject={data.pitchBenefitsObject.ctaObject}
            className="py-5 sm:py-10"
          />
        </Section>

        {/* ------------------------- Testimonials ------------------------- */}
        {data.reviewsObject ||
          (data.textReviewsObject && (
            <Section>
              {data.reviewsObject && (
                <Reviews reviewsObject={data.reviewsObject} />
              )}
              {!data.reviewsObject && data.textReviewsObject && (
                <TextReviews textReviewsObject={data.textReviewsObject} />
              )}
            </Section>
          ))}

        {/*------------------------- join -------------------------*/}
        <Section
          className="bg-brand-beige-40 text-center"
          id={`${data.joinObject.anchor || ""}`}
        >
          <RenderMarkdown markdownContent={data.joinObject.description} />
          <CountdownTimer
            date={data.configObject.date}
            className="my-10"
            classNameDigits="font-semibold"
          />
          <RenderMarkdown markdownContent={data.joinObject.ctaTitle} />
          <Signup signupObject={data.heroObject.signupObject} />
        </Section>

        {/*------------------------- criteriaText -------------------------*/}
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.criteriaText} />
          <Cta ctaObject={data.criteriaCtaObject} className="py-5 sm:py-10" />
        </Section>

        {/*------------------------- faqs -------------------------*/}
        <Section>
          <FAQs faqsObject={data.faqsObject} />
        </Section>

        {/*------------------------- introduction -------------------------*/}
        <Section className="bg-brand-beige-40 text-center">
          <RenderMarkdown
            markdownContent={data.introductionTitle}
            className="text-center"
          />
          <div className="space-y-5 sm:space-y-10">
            <Introduction
              introductionObject={data.introductionObject}
              isReversed={true}
            />
          </div>
        </Section>

        {/*------------------------- finalCta -------------------------*/}
        <Section className="bg-brand-mint">
          <RenderMarkdown
            markdownContent={data.finalCtaText}
            className="text-center"
          />
          <CountdownTimer
            date={data.configObject.date}
            className="my-10"
            classNameDigits="font-semibold"
          />
          <RenderMarkdown markdownContent={data.joinObject.ctaTitle} />
          <div className="mx-auto w-full max-w-2xl">
            <FunnelForm data={data.heroObject.signupObject} />
          </div>
        </Section>
      </Layout>
    </>
  );
};

const OptInTimPage = ({ data }) => {
  return <OptInTimPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default OptInTimPage;

export const pageQuery = graphql`
  query OptInTimPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        configObject {
          date
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
          signupObject {
            cta
            ctaSubtitle
            subtitle
            title
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            funnelId
            href
          }
        }
        famousObject {
          title
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            mobileImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        youKnowObject {
          title
          list {
            description
            iconName
          }
        }
        youKnowText
        imagineObject {
          title
          description
        }
        pitchBenefitsObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          benefitsObject {
            title
            list {
              description
              iconName
            }
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        textReviewsObject {
          title
          list {
            iconName
            review
          }
        }
        reviewsObject {
          title
          reviewList {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        joinObject {
          anchor
          description
          benefitsObject {
            list {
              description
              iconName
            }
          }
          ctaTitle
          ctaObject {
            linkText
            linkUrl
          }
        }
        introductionTitle
        introductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        criteriaText
        criteriaCtaObject {
          linkText
          linkUrl
        }
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        finalCtaText
      }
    }
  }
`;
