import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import IconSelect from "./utilities/IconSelect";

const TextReviews = ({ textReviewsObject }) => {
  return (
    <>
      <RenderMarkdown
            markdownContent={textReviewsObject.title}
            className="text-center"
          />
          <div className="flex flex-col md:flex-row flex-wrap p-5 sm:p-10">
            {textReviewsObject.list.map((element) => (
              <article className="flex w-full md:w-1/2">
                <div className="flex justify-center items-center rounded-full max-h-8 sm:max-h-12 max-w-[3rem] bg-brand-black ">
                  <IconSelect
                    iconName={element.iconName}
                    className={`w-8 sm:w-12 max-h-12 scale-50 fill-current text-white`}
                  />
                </div>
                <RenderMarkdown
                  markdownContent={element.review}
                  className="px-5"
                />
              </article>
            ))}
          </div>
    </>
  );
};

export default TextReviews;
