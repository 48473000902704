import React from "react";

import Video from "./Video";
import RenderMarkdown from "./utilities/RenderMarkdown";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import ConditionalWrap from "./utilities/ConditionalWrap";
import TrackedLink from "./utilities/TrackedLink";
import LinkSetList from "./utilities/LinkSetList";

import { ReactComponent as Pfeil } from "../img/imported_with_webpack/pfeil1.svg";

const Steps = ({ stepsObject }) => {
  console.log(stepsObject)
  return (
    <>
      {stepsObject.title && (
        <RenderMarkdown
          markdownContent={stepsObject.title}
          className="text-center"
        />
      )}
      <ul className="flex flex-col space-y-10 sm:space-y-20">
        {stepsObject.steps.map((el, index) => {
          return (
            <li
              className={`flex items-center flex-col-reverse ${
                index % 2 === 0 ? "lg:flex-row-reverse" : "lg:flex-row"
              }`}
              id={`${el.anchor || ""}`}
            >
              <div
                className={`lg:px-5 space-y-5 sm:space-y-10 flex flex-col items-center ${
                  el.video.cloudflareId ? "lg:w-5/12" : "lg:w-4/12"
                }`}
              >
                {el.imageObject && el.imageObject.image && (
                  <>
                    <ConditionalWrap
                      condition={el.imageObject.linkUrl}
                      wrap={(children) => (
                        <TrackedLink href={el.imageObject.linkUrl}>
                          {children}
                        </TrackedLink>
                      )}
                    >
                      <PreviewCompatibleImage
                        imageObject={el.imageObject}
                        className="max-w-xl lg:max-w-auto max-h-xl lg:max-h-auto"
                      />
                    </ConditionalWrap>
                  </>
                )}
                {el.video.cloudflareId && (
                  <div className="w-screen lg:w-full mt-10 lg:mt-0 max-w-7xl mx-auto">
                    <Video videoObject={el.video} />
                  </div>
                )}
                {el.linkSetList && (
                  <LinkSetList
                    className={"space-y-4 mt-10 lg:mt-0"}
                    linkSetList={el.linkSetList}
                  />
                )}
              </div>
              <Pfeil
                className={`hidden lg:flex items-center w-2/12 text-brand-gray fill-current ${
                  !(index % 2 === 0) && "transform rotate-180"
                }`}
              />
              <div
                className={`lg:px-5 mr-auto ${
                  el.video.cloudflareId ? "lg:w-5/12" : "lg:w-6/12"
                }`}
              >
                <RenderMarkdown
                  markdownContent={el.description}
                  isMarkdown={true}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Steps;
