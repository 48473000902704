import React from "react";
import BrandButton from "./BrandButton";
import RenderMarkdown from "./utilities/RenderMarkdown";

const Cta = ({ ctaObject, className = "", brandButtonProps = {} }) => {
  return (
    <div className={`flex items-center flex-col ${className}`}>
      <BrandButton href={ctaObject.linkUrl} {...brandButtonProps}>
        <RenderMarkdown
          markdownContent={ctaObject.linkText}
          whitespace="normal"
        />
      </BrandButton>
    </div>
  );
};

export default Cta;
