import React, { useState, useEffect } from "react";
import BrandButton from "../BrandButton";
import RenderMarkdown from "./RenderMarkdown";


export default function LinkSet({ linkSetList, className }) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  return (
    <>
      {isMounted && (
        <div className={className}>
          {linkSetList.map((el) => {
            let randIndex = 0;
            if (el.linkSet.length > 1) {
              randIndex = getRandomInt(el.linkSet.length);
            }
            return (
              <div>
                <BrandButton
                  href={el.linkSet[randIndex].linkUrl}
                  className="w-full"
                  color="bg-brand-green"
                >
                  <RenderMarkdown
                    markdownContent={el.linkText}
                    isMarkdown={false}
                    whitespace="normal"
                  />
                </BrandButton>
                {el.linkSubtitle && <div><RenderMarkdown markdownContent={el.linkSubtitle} className="mt-2 text-center" /></div>}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
