import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSection from "../components/HeroSection";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import ProgressBar from "../components/ProgressBar.js";

const DoubleOptInPageTemplate = ({ data }) => {
  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection>
        <div className="space-y-5 sm:space-y-10">
          <div className="max-w-3xl mx-auto">
            <ProgressBar progressObject={data.heroObject.progressObject} />
          </div>
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center"
          />
          <RenderMarkdown markdownContent={data.heroObject.description} />
        </div>
      </HeroSection>
    </Layout>
  );
};

const DoubleOptInPage = ({ data }) => {
  return <DoubleOptInPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default DoubleOptInPage;

export const pageQuery = graphql`
  query DoubleOptInPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          progressObject {
            title
            percentage
            percentageCompleted
          }
          description
        }
      }
    }
  }
`;
